<template>
  <div
    class="twitch-player-parent"
    :class="{
      'is-mini': !isYouTubePlayerFullscreen
    }"
  >
    <small class="version-tag">twitchPlayer running v1</small>
    <!-- PLAYER OUTER -->
    <div
      class="player-outer"
      v-show="!isTwitchPlayerHidden && !isBuffering"
    >
      <!-- PLAYER INNER (IFRAME) -->
      <div
        class="player-inner"
        ref="playerEl"
      >
      </div>

    </div>
  </div>
</template>

<script>
  /**
   * NOTES:
   *
   * https://discuss.dev.twitch.tv/t/refused-to-frame-https-player-twitch-tv-because-an-ancestor-violates-the-following-content-security-policy-directive-frame-ancestors/26820
   * https://stackoverflow.com/questions/7308908/waiting-for-dynamically-loaded-script
   * https://stackoverflow.com/questions/45047126/how-to-add-external-js-scripts-to-vuejs-components
   * https://dev.twitch.tv/docs/embed/everything#introduction
   * https://dev.twitch.tv/docs/embed/video-and-clips#interactive-frames-for-live-streams-and-vods
   * https://stackoverflow.com/questions/57910456/how-can-i-use-a-twitch-script-tag-in-vue
   *
   */
  import { mapGetters } from "vuex";

  export default {
    props: {
      // Rewrite this to an outer shell component later without the mask.
      isShowing: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        embedScript: null,
        isBuffering: null,
        isPlaying: null,
        playerEl: null,
        playerInstance: null,
        scriptExistTimeoutInstance: null,
        volume: 0.3,
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
      }),
      isYouTubePlayerFullscreen() {
        return !!this.getActiveTask("youTubePlayer", "Full");
      },
      isTwitchPlayerHidden() {
        return !!this.getActiveTask("twitchPlayer", "Hide") && !this.isShowing;
      },
      isTwitchPlayerMuted() {
        return !!this.getActiveTask("twitchPlayer", "Mute");
      },
      twitchPlayerSource() {
        return (
          !!this.getActiveTask("twitchPlayer", "Request Media") &&
          this.getActiveTask("twitchPlayer", "Request Media").data &&
          this.getActiveTask("twitchPlayer", "Request Media").data.arg
        );
      },
      playerVolume() {
        return this.getActiveTask("twitchPlayer", "Volume");
      },
    },
    watch: {
      //-----------------------------------------------------------------
      // WATCH: PLAYER MUTE
      //-----------------------------------------------------------------

      isTwitchPlayerMuted(newVal, oldVal) {
        if (newVal !== oldVal && this.playerInstance) {
          this.playerInstance.setMuted(!!newVal);
        }
      },

      //-----------------------------------------------------------------
      // WATCH: PLAYER HIDE
      //-----------------------------------------------------------------

      isTwitchPlayerHidden(newVal, oldVal) {
        if (newVal !== oldVal && this.playerInstance) {
          this.playerInstance.setMuted(!!newVal || !!this.isTwitchPlayerMuted);
        }
      },

      //-----------------------------------------------------------------
      // WATCH: PLAYER SOURCE <source>
      //-----------------------------------------------------------------

      twitchPlayerSource: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            // If new source, init the player.
            if (!!newVal) {
              this.isBuffering = true;
              this.isPlaying = false;
              this.$nextTick(() => this.initPlayer());
            } else if (!!this.playerInstance) {
              this.destroyPlayer();
            }
          }
        },
      },

      //------------------------------------------------------------
      // WATCH: PLAYER VOLUME <arg>
      // Watered down from youTubePlayer (no fader)
      //------------------------------------------------------------

      playerVolume: {
        immediate: true,
        handler(newVal, oldVal) {
          if (!!newVal && newVal !== oldVal) {
            if (this.playerInstance) {
              // const oldVolume = this.playerInstance.getVolume();
              const newVolume = this.playerVolume.data.arg / 100;

              if (newVolume >= 0 && newVolume <= 100) {
                this.playerInstance.setVolume(newVolume);
              }
            }
          }
        },
      },
    },
    mounted() {
      this.playerEl = this.$refs.playerEl;
    },
    beforeDestroy() {
      this.destroyPlayer();
    },
    methods: {
      //-----------------------------------------------------------------
      // INIT PLAYER
      //-----------------------------------------------------------------

      initPlayer() {
        if (!window.Twitch) {
          this.embedScript = document.createElement("script");
          this.embedScript.src = "https://player.twitch.tv/js/embed/v1.js";
          this.embedScript.addEventListener("load", () => this.runPlayer());
          document.head.appendChild(this.embedScript);
          return;
        }
        this.$nextTick(() => this.runPlayer());
      },

      //-----------------------------------------------------------------
      // RUN PLAYER
      //-----------------------------------------------------------------

      runPlayer() {
        this.destroyPlayer();

        const options = {
          // allowfullscreen: true,
          width: "100%",
          height: "100%",
          channel: this.twitchPlayerSource,
          muted: true,
          // video: "<video ID>",
          // collection: "<collection ID>",
          // parent: [
          //   "localhost",
          //   "themitchinghour.com",
          //   "app.themitchinghour.com",
          //   "www.themitchinghour.com",
          // ],
        };

        this.playerInstance = new window.Twitch.Player(this.playerEl, options);

        this.playerInstance.setVolume(this.volume);

        this.playerInstance.addEventListener(window.Twitch.Player.READY, () => {
          // this.isBuffering = true;
          // this.isPlaying = false;
          console.log("READY");
        });

        this.playerInstance.addEventListener(window.Twitch.Player.PLAYING, () => {
          this.isBuffering = false;
          this.isPlaying = true;
          console.log("PLAYING");
        });

        this.playerInstance.addEventListener(window.Twitch.Player.ENDED, () => {
          this.isBuffering = false;
          this.isPlaying = true;
          console.log("ENDED");
        });

        this.playerInstance.addEventListener(window.Twitch.Player.OFFLINE, () => {
          this.isBuffering = false;
          this.isPlaying = true;
          console.log("OFFLINE");
        });

        this.playerInstance.addEventListener(window.Twitch.Player.PLAYBACK_BLOCKED, () => {
          this.isBuffering = false;
          this.isPlaying = true;
          console.log("PLAYBACK_BLOCKED");
        });
      },

      //-----------------------------------------------------------------
      // DESTROY PLAYER
      //-----------------------------------------------------------------

      destroyPlayer() {
        try {
          // this.embedScript.parentNode.removeChild(this.embedScript);

          this.playerEl.querySelectorAll("iframe").forEach((el) => {
            el.parentNode.removeChild(el);
          });

          this.playerInstance = null;
        } catch (error) {
          // fail silently
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .twitch-player-parent {
    display: flex; // [LOCKED]
    align-items: center; // [LOCKED]
    justify-content: center; // [LOCKED]

    position: fixed;
    top: rem-calc(-35); // perfect
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - #{rem-calc(80)});
    // background: aqua;
    // border: 1px dashed yellow;
    z-index: -2; // little hack, so plays behind lobby

    &.is-mini {
      top: 0;
      height: 100%;
    }
  }

  .version-tag {
    position: fixed;
    bottom: 0;
    right: 0;
    background: rgba(black, 0.5);
    color: white;
  }

  //---------------------------------------------------------
  // PLAYER OUTER
  // http://jsfiddle.net/DZTvR/13/
  // https://stackoverflow.com/questions/16549919/is-there-a-way-to-make-the-css-property-mask-image-responsive
  // https://stackoverflow.com/questions/29381545/how-to-make-circle-shaped-video-player-on-safari-browser
  //---------------------------------------------------------

  .player-outer {
    width: 100%;
    height: 100%;
    // This allows TV to remain clear when YT is fullscreen.
    mask: url("./assets/img/fullscreen-mask-2.svg") bottom -110px right;
    mask-repeat: no-repeat;
    mask-size: 1920px; // TODO: make this big one day for 1440 screens(?)
    transform-origin: center;
    overflow: hidden;
    will-change: transform;
  }

  .is-mini .player-outer {
    position: absolute;
    bottom: rem-calc(25);
    right: rem-calc(35);
    width: rem-calc(244);
    height: rem-calc(196);
    mask: none;
    transform-origin: bottom right;
    transform: rotate(-15deg);
  }

  //---------------------------------------------------------
  // PLAYER INNER (IFRAME)
  //---------------------------------------------------------

  .player-inner {
    width: 100%;
    height: 100%;
    transform: scale(1.05);
    will-change: transform;
  }

  .is-mini .player-inner {
    transform: scale(1.8); // 1.5 safe?
  }
</style>
